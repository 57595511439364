<template>
  <div class="container">
    <el-dialog title="转交助手" width="600px" v-model="dialogVisible">
        <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="100px" class="ruleForm">
            <el-form-item label="助手" prop="robot" required>
                 <el-select v-model="ruleForm.robot" placeholder="全部" filterable value-key="ID">
                     <el-option v-for="item in robotList" :key="item.ID" :label="item.NickName+'('+item.WxAlias+')'" :value="item">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, onMounted, unref } from "vue";
import { TransferGroup } from "@/helper/group";
import { GetExtRobotList } from "@/helper/robot";
import { ElMessageBox, ElMessage } from "element-plus";
export default {
  setup(props, context) {
    let reClick = false
    const dialogVisible = ref(false);
    const robotList = ref([]);
    const ruleFormRef = ref(null);
    const ruleForm = reactive({
      robot: {}
    });
    const sourceData = reactive({
      item: {}
    });
    const sid=ref("")

    const rules = reactive({
      robot: [
        {
          type: "object",
          validator: validatePass,
          required: true,
          message: "请选择接替的助手"
        }
      ]
    });

    function validatePass(rule, value, callback) {
      if (!ruleForm.robot.ID) {
        callback(new Error("请选择接替的助手"));
      } else {
        callback();
      }
    }

    function initCop(item,SID) {
      console.log(item);
      dialogVisible.value = true;
      ruleForm.robot = {};
      sourceData.item = item;
      sid.value=SID
      robotList.value = [];
      searchAction();
    }

    //搜索
    function searchAction() {
      GetExtRobotList({GroupID:sid.value}).then(res => {
        console.log(res);
        robotList.value = res.List;
        if(res.List&&res.List.length>0){
          ruleForm.robot = res.List[0];
        }else {
           ruleForm.robot = {}
        }
      });
    }
    /*************点击事件****************/
    //确定表单
    const submitForm = async () => {
      if (reClick) return;
      reClick = true;
      setTimeout(() => {
        reClick = false;
      }, 3000);
      const form = unref(ruleFormRef);
      if (!form) return;
      try {
        await form.validate();
        let param = {
          GroupID: sid.value,
          // OldSerialNo:ruleForm.robot.SerialNo,
          NewSerialNo: ruleForm.robot.SerialNo
        };
        TransferGroup(param).then(res => {
          dialogVisible.value = false;
          context.emit("onConfirm");
        });
      } catch (error) {
        console.log(error);
      }
    };

    return {
      dialogVisible,
      initCop,
      submitForm,
      robotList,
      ruleForm,
      rules,
      ruleFormRef,
      validatePass,
      sid
    };
  }
};
</script>
<style lang='scss' scoped>
</style>