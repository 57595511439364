<template>
  <div>
    <div class="slide-wrap">
      <div class="header-left-wrap">
        <div class="info-header">
          <div class="header-img">
            <div class="imgs">
              <img src="@/assets/imgs/default_group.jpeg" />
            </div>
            <el-button  @click="resfresh">
              <el-icon  :size="12"><RefreshLeft /></el-icon>刷新数据
            </el-button>
          </div>
          
          <div class="header-base">
            <div class="base-wrap">
              <div>群名称:{{sourceData.data.GroupName}}</div>
              <el-button style="margin-left:10px" type="primary" @click="editGroupName">编辑</el-button>
            </div>
            <div class="base-wrap">
              <div>群码:</div>
              <el-button v-if="sourceData.data.isRefresh" type="primary" @click="refreshGroupCode(sourceData.data)">刷新群码</el-button>
              <el-image v-else-if="sourceData.data.GroupQrCode&&sourceData.data.GroupQrCode.QrCodeUrl" class="groupImg" :src="sourceData.data.GroupQrCode.QrCodeUrl"  @click="imageClick(sourceData.data)"></el-image>
              <el-image v-else class="groupImg" src=""  @click="imageClick(sourceData.data)"></el-image>
            </div>
            <div class="base-wrap">
              <div>群主:{{sourceData.data.GroupAdmin.NickName}} <template v-if="sourceData.data.GroupAdmin.WxAlias">({{sourceData.data.GroupAdmin.WxAlias}})</template> </div>
            </div>
            <div class="base-wrap">
              <div>管理员:</div>
              <div class="manager-wrap" v-for="(item,index) in sourceData.data.ManagerList" :key="index">{{item.NickName}}<template v-if="index + 1 < sourceData.data.ManagerList.length">、</template></div>
              <el-button style="margin-left:10px" type="primary" @click="editManager">编辑</el-button>
            </div>
            <div class="base-wrap">
              <div>管理助手:{{sourceData.data.Robot.NickName}}({{sourceData.data.Robot.WxAlias}})</div>
              <el-button style="margin-left:10px" type="primary" @click="editRobot">编辑</el-button>
            </div>
            <div class="base-wrap">
              <div>群标签:</div>
              <div class="tag-wrap">
                <el-tag
                  class="tag-cursor"
                  v-for="(item, index) in sourceData.data.GroupTagList"
                  :key="index"
                  >{{ item.TagName }}</el-tag>
                <el-button type="primary" @click="editTagClick">编辑</el-button>
              </div>
            </div>
          </div>

          <div class="header-base"> 
            <div class="base-wrap">
              <div>入群欢迎语:</div>
              <div v-if="sourceData.data.WelcomeMsg.TaskName">{{sourceData.data.WelcomeMsg.TaskName}}</div>
              <el-button style="margin-left:10px" type="primary" @click="editGroupWelcome">编辑</el-button>
            </div>
            <div class="base-wrap">
              <div>其他操作:</div>
              <el-button style="margin-left:10px" @click="logoffAction">取消开通群</el-button>
            </div>
            <div class="base-wrap">
              <div>RPA助手:{{sourceData.data.RPA.NickName}}({{sourceData.data.RPA.UserID}})</div>
              <el-button style="margin-left:10px" type="primary" @click="editRPA">设置RPA助手</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="space"></div>
    <div class="table-head">
      <div class="table-search">
        <el-space>
          <div style="margin-right: 10px">
            <span>成员名称:</span>
            <el-input
              v-model="searchOption.memberName"
              placeholder="请输入成员名称"
            ></el-input>
          </div>
        </el-space>
        <div>
          <el-button type="primary" @click="searchAction">搜索</el-button>
          <el-button @click="resetAction">重置</el-button>
        </div>
      </div>
    </div>
    <div class="space"></div>
    <div class="table-box">
    <div class="list-title">
			<div>群成员(共<span>{{pageObj.TotalRow||0}}</span>个)</div>
            <div>
                <el-button type="primary" @click="pullAction">拉企业员工进群</el-button>
                <el-button type="primary" @click="exportAction">导出</el-button>
            </div>
		</div>
      <el-table :data="sourceData.groups" style="width: 100%" border @sort-change="changeSort">
        <el-table-column label="成员头像" width="70px">
            <template #default="scope">
                <div class="action-wrap">
                    <img src="@/assets/imgs/default_user.png" alt="" v-if="!scope.row.MemberHeadImgUrl">
                    <img :src="scope.row.MemberHeadImgUrl" alt="" v-else>
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="MemberNickName" label="成员昵称"></el-table-column>
        <el-table-column prop="MemberGroupNickName" label="成员群昵称"></el-table-column>
        <el-table-column label="邀请人" prop="MemberFatherNickName"></el-table-column>
        <el-table-column label="邀请入群人数" prop="SonsCount"  sortable='custom'></el-table-column>
        <el-table-column label="入群时间" prop="CreateTime"  sortable='custom'></el-table-column>
        <el-table-column prop="MemberIdentiyStr" label="身份" sortable='custom'></el-table-column>
        <el-table-column label="白名单" prop="IsWhiteList" sortable='custom'>
          <template #default="scope">
                <div v-if="scope.row.IsWhiteList!=-1">
                  <el-switch v-model="scope.row.IsWhiteList" active-color="#13ce66" :active-value="1" :inactive-value="0" @change="changeSwitch(scope.row)"/>
                </div>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="130">
          <template #default="scope">
            <div class="cell-row" v-if="scope.row.MemberIdentity==0">
              <el-button type="primary" @click="outGroupTalk(scope.row)">踢出群聊</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <TableFoot
        :pagination="pageObj"
        @changePage="changePage"
        @changeSize="changeSize"
      />
    </div>
    <EditGroupNameCop ref="editNameRef" @onConfirm="sureGroupNameAction"/>
    <GroupSelectCop ref="groupSelectDialog" @onConfirm="chooseGroupAction" />
    <TransforRobotCop ref="transforRobotRef"  @onConfirm="changerRobotAction"/>
    <GroupFieldCop ref="groupFieldRef" @onConfirm="sureAction"/>
    <GroupSettingDialog ref="groupSettingRef" @onConfirm="sureWelcomeAction"/>
    <GroupSelectCop ref="groupSelect2Dialog" @onConfirm="chooseGroup2Action" />
    <QrcodeShowCop ref='qrcodeShowRef' />
    <SetRPACop ref="setRpaRef" @onConfirm="sureAction"/>
  </div>
</template>

<script>
import { reactive, ref, unref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import {GroupGetInfo,GetGroupMemberList,RemoveAllGroup,GroupMemberSetRoleWhite,GroupMemberRemoveRoleWhite,GroupSetGroupAdmin,SetGroupUnOpen,GroupInviteCompanyContact2Group,GetGroupQRcode}  from '@/helper/group'
import TableFoot from "@/components/TableFoot";
import EditGroupNameCop from '@/components/GroupManager/EditGroupName'
import TransforRobotCop from '@/components/GroupManager/TransforRobotCop'
import GroupSelectCop from "@/components/GroupSelectCop";
import GroupFieldCop from '@/components/GroupFieldCop'
import GroupSettingDialog from '@/components/GroupManager/GroupSettingDialog'
import { RefreshLeft } from "@element-plus/icons";
import QrcodeShowCop from '@/components/GroupManager/QrcodeShowCop'
import SetRPACop from "@/components/GroupManager/SetRPACop"
import Tool from "@/assets/js/tools";

export default {
  name: "GroupInfo",
  components: {TableFoot,QrcodeShowCop,RefreshLeft,EditGroupNameCop,GroupSelectCop,TransforRobotCop,GroupFieldCop,GroupSettingDialog,SetRPACop},
  setup() {
    const router = useRouter();
    const SID = useRoute().query.SID;
    const editNameRef=ref(null)
    const groupSelectDialog=ref(null)
    const transforRobotRef=ref(null)
    const groupFieldRef=ref(null)
    const groupSettingRef=ref(null)
    const groupSelect2Dialog=ref(null)
    const qrcodeShowRef = ref(null)
    const sourceData = reactive({
      data: {
        GroupAdmin:"",
        Robot:"",
        WelcomeMsg:"",
        RPA:""
      },
      groups: []
    });
    const searchOption=reactive({
        memberName:"",
        orderBy:0
    })
    onMounted(() => {
      loadData();
    });

    function loadData() {
      requestGroupInfo();
    }

    //请求成员
    function requestGroupInfo() {
        GroupGetInfo({GroupID:SID}).then(res=>{
          sourceData.data=res.GroupInfo
          console.log(sourceData.data);
          sourceData.data.endTime = Tool.GMTToStr(sourceData.data.GroupQrCode.QrCodeLastUpdateTime,2,7)
          sourceData.data.refreshTime = Tool.GMTToStr(sourceData.data.GroupQrCode.QrCodeLastUpdateTime,2,5)
          if(new Date().getTime()>new Date(sourceData.data.refreshTime).getTime()){
            sourceData.data.isRefresh= true
          }
        })
        requestMemberList();
    }

    //请求成员列表
    function requestMemberList() {
      GetGroupMemberList(dealwithWhere()).then(res=>{
        sourceData.groups=res.List
        pageObj.TotalRow=res.TotalCount
        res.List.forEach(item=>{
            item.MemberIdentiyStr=changeMemberStatu(item.MemberIdentity)
        })
      })
    }

    //转化身份
    function changeMemberStatu(num){
      if(num==0){
        return "普通群成员"
      } else if(num==1){
        return "管理员"
      } else if(num==2){
        return "群主"
      } else if(num==3){
        return "白名单"
      }
    }
    function dealwithWhere() {
      return {
          Name:searchOption.memberName||"",
          PageNum:pageObj.PageIndex,
          PageSize:pageObj.PageSize,
          GroupID:SID,
          OrderBy:searchOption.orderBy||0,
        }
    }

    const setRpaRef=ref(null)
    /****************点击事件*******************/
    //编辑
    function editRPA(){
      setRpaRef.value.initCop(1,[],sourceData.data.RPA,SID)
    }
    //导出
    function exportAction(){
      let param = dealwithWhere()
      param.PageSize=999999;
      param.Output = 1
      GetGroupMemberList(param).then(res=>{
        window.location.href = res.File;
        ElMessage({
            type: 'success',
            message: '导出成功!',
        });
      })
    }

    //加入白名单
    function changeSwitch(item) {
      console.log(item);
      if(item.IsWhiteList==0){
        ElMessageBox.confirm(`确认将用户【${item.MemberNickName}】移除白名单？`, '确认提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          GroupMemberRemoveRoleWhite({MemberSerialNo:item.MemberSerialNo}).then(res=>{
            ElMessage({
              type: 'success',
              message: '移除成功!',
            });
             requestMemberList();
          }) 
        }).catch(() => {
            ElMessage({
              type: 'info',
              message: '已取消',
            });
            item.IsWhiteList=1
          });
      }else{
         GroupMemberSetRoleWhite({MemberSerialNo:item.MemberSerialNo}).then(res=>{
            ElMessage({
              type: 'success',
              message: '设置成功!',
            });
             requestMemberList();
          }) 
      }
    }
    //编辑群名
    function editGroupName() {
        editNameRef.value.initCop(sourceData.data,SID)
    }
    //确认群名
    function sureGroupNameAction() {
      requestGroupInfo();
    }

    //编辑管理员
    function editManager() {
        groupSelectDialog.value.initCop(5,sourceData.data.ManagerList,true,sourceData.data,SID)
    }
    //确认管理员
    function chooseGroupAction(arr,item) {
      let param={
        RobotSerialNo:item.Robot.SerialNo,
        GroupID:SID,
        MemberSerialNos:arr.map(item=>item.MemberSerialNo)
      }
      GroupSetGroupAdmin(param).then(res=>{
        requestGroupInfo();
      })
    }


    //管理助手
    function editRobot() {
      transforRobotRef.value.initCop(sourceData.data,SID);
    }
    //确认助手
    function changerRobotAction() {
      requestGroupInfo();
    }

    //群标签
    function editTagClick(){
      sourceData.data.GroupTagList.forEach(v=>{
        v.ID = v.TagID;
      })
      groupFieldRef.value.initCop(1,sourceData.data.GroupTagList,1,[SID])
    }
    //确认标签
    function sureAction() {
      requestGroupInfo();
    }

    //欢迎语
    function editGroupWelcome(){
      groupSettingRef.value.initCop(0,[sourceData.data],true,[sourceData.data.WelcomeMsg.WelcomeMsgID],SID)
    }
    //确认欢迎语
    function sureWelcomeAction() {
      requestGroupInfo()
    }

    //取消
    function logoffAction(){
        ElMessageBox.confirm(`【${sourceData.data.GroupName}】注销后将无法二次开群，您确认要注销吗？？`, '确认提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
            let param ={
              GroupID:SID,
            }
            SetGroupUnOpen(param).then(res=>{
              ElMessage({
                type: 'success',
                message: '注销成功!',
              });
              router.go(-1)
            })
        }).catch(() => {
            ElMessage({
              type: 'info',
              message: '已取消',
            });
          });
    }

    //拉人
    function pullAction() {
      groupSelect2Dialog.value.initCop(6,[],true,sourceData.data,SID)
    }
    //确认拉人
    function chooseGroup2Action(arr,item) {
      let param={
        GroupID:SID,
        MemberSerialNos:arr.map(item=>item.MemberSerialNo),
      }
      GroupInviteCompanyContact2Group(param).then(res=>{
        ElMessage({
            type: 'success',
            message: '操作成功!',
          });
        requestMemberList()
      })
    }

    //踢出群聊
    function outGroupTalk(item) {
      let param={
        RobotSerialNo:sourceData.data.Robot.SerialNo,
        SetBlackList:0,
        MemberSeiralNo:item.MemberSerialNo
      }
      ElMessageBox.confirm(`确认将用户【${item.MemberNickName}】从所有群中踢出？`, '确认提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          RemoveAllGroup(param).then(res=>{
            searchAction();
            ElMessage({
              type: 'success',
              message: '操作成功!',
            });
          })
        }).catch(() => {
            ElMessage({
              type: 'info',
              message: '已取消',
            });
          });
        
    }

    //排序
    const changeSort=(obj)=>{
      pageObj.PageIndex = 1
       if(obj.order == "descending"&&obj.prop=='SonsCount'){
        searchOption.orderBy =2;
        requestMemberList();
      } else if(obj.order == "descending"&&obj.prop=='CreateTime'){
        searchOption.orderBy = 4;
        requestMemberList();
      }else if(obj.order == "descending"&&obj.prop=='MemberIdentiyStr'){
        searchOption.orderBy = 6;
        requestMemberList();
      } else if(obj.order == "descending"&&obj.prop=='IsWhiteList'){
        searchOption.orderBy = 8;
        requestMemberList();
      }else if(obj.order == "ascending"&&obj.prop=='SonsCount'){
        searchOption.orderBy = 1;
        requestMemberList();
      } else if(obj.order == "ascending"&&obj.prop=='CreateTime'){
        searchOption.orderBy = 3;
        requestMemberList();
      } else if(obj.order == "ascending"&&obj.prop=='MemberIdentiyStr'){
        searchOption.orderBy = 5;
        requestMemberList();
      } else if(obj.order == "ascending"&&obj.prop=='IsWhiteList'){
        searchOption.orderBy = 7;
        requestMemberList();
      }  else {
        searchOption.orderBy = 0;
        requestMemberList();
      }      
    }


    //刷新
    function resfresh(){
      requestGroupInfo();
    }

    //搜索
    function searchAction() {
      pageObj.PageIndex = 1;
      loadData();
    }
    function resetAction() {
      searchOption.memberName = "";
      searchOption.date = "";
      requestMemberList();
    }

     //刷新群码
    function refreshGroupCode(row){
      GetGroupQRcode({GroupID:row.GroupID,GroupSerialNo:row.GroupSerialNo}).then(res=>{
        ElMessage.success('刷新成功')
        searchAction()
      })
    }

    //图片点击
    function imageClick(item){
      qrcodeShowRef.value.initCop(item)
    }

 
    /****************TableFoot*******************/
    const pageObj = reactive({
      TotalRow: 0,
      PageIndex: 1,
      PageSize: 10,
    });
    //页码改变
    function changePage(index) {
      pageObj.PageIndex = index;
      loadData();
    }
    function changeSize(size) {
      pageObj.PageSize = size;
      pageObj.PageIndex = 1;
      loadData();
    }
    return {
     sourceData,
     pageObj,
     searchOption,
     changePage,
     changeSize,
     searchAction,
     resetAction,
     requestMemberList,
     requestGroupInfo,
     dealwithWhere,
     editGroupName,
     editManager,
     editRobot,
     editTagClick,
     editGroupWelcome,
     outGroupTalk,
     resfresh,
     changeSort,
     changeMemberStatu,
     changeSwitch,
     exportAction,
     editNameRef,
     sureGroupNameAction,
     groupSelectDialog,
     chooseGroupAction,
     changerRobotAction,
     transforRobotRef,
     groupFieldRef,
     sureAction,
     groupSettingRef,
     sureWelcomeAction,
     pullAction,
     groupSelect2Dialog,
     logoffAction,
     chooseGroup2Action,
     refreshGroupCode,
     imageClick,
     qrcodeShowRef,
     editRPA,
     setRpaRef
    };
  },
};
</script>
<style lang='scss' scoped>
.slide-wrap {
  min-height: 0 !important;
}
.header-left-wrap {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background: #fff;
  .info-header {
    width: 100%;
    display: flex;
    .header-img {

      margin-right: 20px;
      .imgs{
         width: 70px;
        height: 60px;
        margin: 0 auto;
        margin-bottom: 10px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .header-base {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
    //   justify-content: space-around;
      font-size: 16px;
      .base-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        color: $color-text;
        font-size: 14px;
        padding-bottom: 12px;
        span {
          color: $color-common;
        }
        p {
          color: $color-common;
          margin-left: 5px;
        }
        .tag-cursor {
          cursor: pointer;
        }
        .manager-wrap{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
      }
    }
    .wrap {
      // width: 17%;
      font-size: 14px;
      display: flex;
      justify-content: center;
      .title {
        width: 80px;
      }
      .qrcode-img {
        width: 60px;
        height: 60px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.list-title {
    
    div span{
        color:$color-common;
    }
} 
.space {
  widows: 100%;
  height: 5px;
  background-color: #eee;
}
.table-search {
  justify-content: flex-start;
}
.cell-row {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 5px;

  img {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }

  .cell-important {
    color: $color-common;
  }
}
.action-wrap{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    i{
        margin-left: 2px;
    }

    img{
        width: 40px;
        height: 40px;
    }

}
:deep().el-empty {
  padding: 0;
}
:deep().el-tag--dark{
  color:#000 !important;
  background-color: #fff !important;
  border-color: $color-border !important;
  cursor: pointer;
}
:deep().el-tag{
  color:$color-common !important;
  border-color: $color-common !important;
  background-color: #fff !important;
  padding: 0px 10px !important;
  margin: 5px !important;
}

.refresh-text{
  color:$color-common;
  font-size:12px;
  cursor: pointer;
}
.groupImg{
  width:40px;
  height:40px;
  cursor:pointer;
}

</style>